import React from 'react'
import styled from 'styled-components'
import { Text, Flex, Heading, IconButton, ArrowBackIcon } from '@pancakeswap/uikit'
import { Link } from 'react-router-dom'
import Settings from './Settings'
import Transactions from './Transactions'
import QuestionHelper from '../QuestionHelper'

interface Props {
  title: string
  subtitle: string
  helper?: string
  backTo?: string
  noConfig?: boolean
}

const AppHeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding: 14px 6px 20px;
  margin: 16px 16px 20px;
  // width: 100%;
  box-sizing: content-box;
  border-top: 4px solid transparent;
  border-image-slice: 1;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

const AppHeader: React.FC<Props> = ({ title, subtitle, helper, backTo, noConfig = false }) => {
  return (
    <AppHeaderContainer>
      <Flex flexDirection="row" style={{ justifyContent: 'space-between', width: 'calc(100%)' }}>
        <div>
          <Heading as="h2" style={{ fontSize: '28px' }}>
            {title}
          </Heading>
          <Text style={{ color: 'white' }} fontSize="14px">
            {subtitle}
          </Text>
        </div>
        {!noConfig && (
          <Flex style={{ justifyContent: 'flex-start' }}>
            <Settings />
            <Transactions />
          </Flex>
        )}
      </Flex>
    </AppHeaderContainer>
  )
}

export default AppHeader
