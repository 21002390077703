// Set of helper functions to facilitate wallet setup
import { BASE_URL } from 'config'
import { chainSelectList } from 'config/constants/chain'
import ContractAddress from '../config/constants/contracts'

/**
 * Prompt the user to add BSC as a network on wallet, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async () => {
  const provider = window.ethereum
  if (provider) {
    console.log('🚀 ~ setupNetwork ~ provider:', provider)
    const chainId = parseInt(localStorage.getItem('CurrentChain'), 10)
    console.log('setup network', chainId, localStorage.getItem('CurrentChain'))
    const network = chainSelectList.find((v) => v.chainId === chainId)
    console.log('🚀 ~ setupNetwork ~ network:', network)
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: network.displayName,
            nativeCurrency: {
              name: network.name,
              symbol: network.symbol,
              decimals: 18,
            },
            rpcUrls: [network.rpc],
            blockExplorerUrls: [`${network.explorer}`],
          },
        ],
      })
      return true
    } catch (error) {
      console.error('Failed to setup the network in wallet:', error)
      return false
    }
  } else {
    console.error("Can't setup the Deam network on wallet because window.ethereum is undefined")
    return false
  }
}

/**
 * Prompt the user to add a custom token to wallet
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (tokenAddress: string, tokenSymbol: string, tokenDecimals: number) => {
  const tokenAdded = await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: `${BASE_URL}/images/tokens/${tokenAddress}.png`,
      },
    },
  })

  return tokenAdded
}
