import React from 'react'
import { CardHeader, Heading, Tag, Flex } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { Token } from 'config/constants/types'
import { getImageUrlFromToken, TokenPairImage } from 'components/TokenImage'
import CakeVaultTokenPairImage from '../CakeVaultCard/CakeVaultTokenPairImage'

const Wrapper = styled(CardHeader)<{ isFinished?: boolean; background?: string }>`
  padding: 24px;
`

const MultiplierTag = styled(Tag)`
  background-color: #2b70fa;
  padding: 6px 14px;
`

const StyledCardHeader: React.FC<{
  earningToken: Token
  stakingToken: Token
  isAutoVault?: boolean
  isFinished?: boolean
  isStaking?: boolean
  multiPlayer?: number
}> = ({ earningToken, stakingToken, isFinished = false, isAutoVault = false, isStaking = false, multiPlayer = 0 }) => {
  const { t } = useTranslation()
  const isCakePool = earningToken.symbol === 'Pizzaswap' && stakingToken.symbol === 'Pizzaswap'
  const background = isStaking ? 'bubblegum' : 'cardHeader'

  const getHeadingPrefix = () => {
    if (isAutoVault) {
      // vault
      return t('Auto')
    }
    if (isCakePool) {
      // manual cake
      return t('Manual')
    }
    // all other pools
    return t('Earn')
  }

  const getSubHeading = () => {
    if (isAutoVault) {
      return t('Automatic restaking')
    }
    if (isCakePool) {
      return t('Earn Pizza, stake Pizza')
    }
    return t('Stake %symbol%', { symbol: stakingToken.symbol })
  }

  return (
    <Wrapper isFinished={isFinished} background={background}>
      <Flex width="100%" flexDirection="row" justifyContent="flex-start" mb="18px">
        <img src={getImageUrlFromToken(earningToken)} alt="Token" width={48} height={48} />
      </Flex>
      <Flex width="100%" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
        <Heading mb="4px">{`${getHeadingPrefix()} ${earningToken.symbol}`}</Heading>
        <Flex justifyContent="center">
          <MultiplierTag variant="secondary">{`${multiPlayer}x`}</MultiplierTag>
        </Flex>
      </Flex>
    </Wrapper>
  )
}

export default StyledCardHeader
