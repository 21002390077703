import { ChainId, Currency } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = addresses.networkId
  return address[chainId] // ? address[chainId] : address[ChainId.ETHEREUM]
}
export const getFactoryAddress = () => {
  return getAddress(addresses.factory)
}
export const getCakeAddress = () => {
  return getAddress(tokens.pcn.address)
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getWbnbAddress = () => {
  return getAddress(tokens.weth.address)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}

export const getNativeSymbol = (currency: Currency | string) => {
  const nativeTokenSymbol = {
    [ChainId.SEPOLIA]: 'ETH',
    [ChainId.BSC_TESTNET]: 'BNB',
    [ChainId.AMOY]: 'MATIC'
  }
  if (typeof currency === "string") {
    return currency === 'Ether' ? nativeTokenSymbol[Number(addresses.networkId)] : currency;
  }
  return currency?.symbol === 'Ether' ? nativeTokenSymbol[Number(addresses.networkId)] : currency?.symbol
}