import BigNumber from 'bignumber.js'
import styled from 'styled-components'

import React from 'react'
import { CardBody, Flex, Text, CardRibbon } from '@pancakeswap/uikit'
import UnlockButton from 'components/UnlockButton'
import { useTranslation } from 'contexts/Localization'
import { BIG_ZERO } from 'utils/bigNumber'
import { Pool } from 'state/types'
import AprRow from './AprRow'
import { StyledCard, StyledCardInner } from './StyledCard'
import CardFooter from './CardFooter'
import StyledCardHeader from './StyledCardHeader'
import CardActions from './CardActions'

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.cardBorder};
  height: 1px;
  margin: 28px auto;
  width: 100%;
`

const PoolCard: React.FC<{ pool: Pool; account: string }> = ({ pool, account }) => {
  const { sousId, stakingToken, earningToken, isFinished, userData, depositFee, lockup } = pool
  const { t } = useTranslation()
  const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  const accountHasStakedBalance = stakedBalance.gt(0)

  const secondToFormat = (_s: string | number) => {
    const second = Number(_s) || 0
    const d = Math.floor(second / (3600 * 24))
    const h = Math.floor((second % (3600 * 24)) / 3600)
    const m = Math.floor((second % 3600) / 60)
    const s = Math.floor(second % 60)

    const dDisplay = d > 0 ? d + (d === 1 ? ' Day ' : ' Days ') : ''
    const hDisplay = h > 0 ? h + (h === 1 ? ' Hour ' : ' Hours ') : ''
    const mDisplay = m > 0 ? m + (m === 1 ? ' Minute ' : ' Minutes ') : ''
    const sDisplay = s > 0 ? s + (s === 1 ? ' Second' : ' Seconds') : ''
    return dDisplay + hDisplay + mDisplay + sDisplay
  }

  return (
    <StyledCard
      isFinished={isFinished && sousId !== 0}
      ribbon={isFinished && <CardRibbon variantColor="textDisabled" text={t('Finished')} />}
    >
      <StyledCardInner>
        <StyledCardHeader
          isStaking={accountHasStakedBalance}
          earningToken={earningToken}
          stakingToken={stakingToken}
          isFinished={isFinished && sousId !== 0}
          multiPlayer={pool.totalStaked.c?.length || 0}
        />

        <CardBody>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
            <AprRow pool={pool} />
            <Flex justifyContent="space-between">
              <Text color="textSubtle">{t('Earn')}:</Text>
              <Text>{pool.earningToken.symbol}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text color="textSubtle">{t('Deposit Fee')}:</Text>
              <Text>{depositFee}%</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text color="textSubtle">{t('Harvest Lockup')}:</Text>
              <Text>{secondToFormat(lockup)}</Text>
            </Flex>
          </div>
          <Divider />
          <Flex mt="24px" flexDirection="column">
            {account ? (
              <CardActions pool={pool} stakedBalance={stakedBalance} />
            ) : (
              <>
                <Text mb="10px" textTransform="uppercase" fontSize="12px" color="textSubtle" bold>
                  {t('Start earning')}
                </Text>
                <UnlockButton />
              </>
            )}
          </Flex>
        </CardBody>
        <CardFooter pool={pool} account={account} />
      </StyledCardInner>
    </StyledCard>
  )
}

export default PoolCard
