import React from 'react'
import { Menu as UikitMenu } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useChain from 'hooks/useChain'
import useAuth from 'hooks/useAuth'
import { usePriceCakeBusd } from 'state/hooks'
import { chainSelectList } from 'config/constants/chain'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import config from './config'

const Menu = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { isDark, toggleTheme } = useTheme()
  const { currentChain, toggleChain } = useChain()
  const cakePriceUsd = usePriceCakeBusd()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  console.log('🚀 ~ Menu ~ chainId:', chainId)
  // const { library } = useWeb3React();

  // React.useEffect(() => {
  //   toggleTheme()
  //   console.log(chainId)
  // }, [chainId])

  const setNetwork = async (_chainId: number) => {
    let hexId = `${Number(_chainId).toString(16).toString()}`
    if (window.ethereum) {
      hexId = `0x${hexId}`
      console.log('🚀 ~ setNetwork ~ hexId:', hexId)
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: hexId,
            },
          ],
        })
        toggleChain(_chainId)
      } catch (err: any) {
        console.log(err)
      }
    } else {
      const network = chainSelectList.find((v) => v.chainId === _chainId)
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: hexId,
            chainName: network.displayName,
            rpcUrls: [network.rpc],
            nativeCurrency: {
              name: network.name,
              symbol: network.symbol,
              decimals: network.decimals,
            },
            blockExplorerUrls: [network.explorer],
          },
        ],
      })
      toggleChain(_chainId)
    }
  }

  return (
    <UikitMenu
      account={account}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      currentNetwork={currentChain}
      networks={chainSelectList}
      setNetwork={(_chainId: number) => {
        setNetwork(_chainId)
      }}
      cakePriceUsd={cakePriceUsd.toNumber()}
      links={config(t)}
      {...props}
    />
  )
}

export default Menu
