import { ChainId, Token } from '@pancakeswap/sdk'
import ContractAddress from './contracts'

export const PCN: { [chainId: number]: Token } = {
  // [ChainId.ETHEREUM]: new Token(ChainId.ETHEREUM, ContractAddress.pcn[ChainId.ETHEREUM], 18, 'Pizza', 'Pizza Token',),
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA, ContractAddress.pcn[ChainId.SEPOLIA], 18, 'Pizzaswap', 'Pizzaswap'),
  // [ChainId.BSC]: new Token(ChainId.BSC, ContractAddress.pcn[ChainId.BSC], 18, 'Pizza', 'Pizzaswap',),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    ContractAddress.pcn[ChainId.BSC_TESTNET],
    18,
    'Pizzaswap',
    'Pizzaswap',
  ),
  // [ChainId.POLYGON]: new Token(ChainId.POLYGON, ContractAddress.pcn[ChainId.POLYGON], 18, 'Pizza', 'Pizza Token',),
  [ChainId.AMOY]: new Token(ChainId.AMOY, ContractAddress.pcn[ChainId.AMOY], 18, 'Pizza', 'Pizza Token',),
}
export const BUSD: { [chainId: number]: Token } = {
  // [ChainId.ETHEREUM]: new Token(ChainId.ETHEREUM, ContractAddress.busd[ChainId.ETHEREUM], 18, 'USDT', 'Pegged USDT',),
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA, ContractAddress.busd[ChainId.SEPOLIA], 18, 'USDT', 'Pegged USDT'),
  // [ChainId.BSC]: new Token(ChainId.BSC, ContractAddress.busd[ChainId.BSC], 18, 'USDT', 'Pegged USDT',),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    ContractAddress.busd[ChainId.BSC_TESTNET],
    18,
    'USDT',
    'Pegged USDT',
  ),
  // [ChainId.POLYGON]: new Token(ChainId.POLYGON, ContractAddress.busd[ChainId.POLYGON], 18, 'USDT', 'Pegged USDT',),
  [ChainId.AMOY]: new Token(ChainId.AMOY, ContractAddress.busd[ChainId.AMOY], 18, 'USDT', 'Pegged USDT',),
}

export const WETH: { [chainId: number]: Token } = {
  // [ChainId.ETHEREUM]: new Token(ChainId.ETHEREUM, ContractAddress.weth[ChainId.ETHEREUM], 18, 'WETH', 'Wrapped ETH',),
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA, ContractAddress.weth[ChainId.SEPOLIA], 18, 'WETH', 'Wrapped ETH'),
  // [ChainId.BSC]: new Token(ChainId.BSC, ContractAddress.weth[ChainId.BSC], 18, 'WBNB', 'Wrapped BNB',),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    ContractAddress.weth[ChainId.BSC_TESTNET],
    18,
    'WBNB',
    'Wrapped BNB',
  ),
  // [ChainId.POLYGON]: new Token(ChainId.POLYGON, ContractAddress.weth[ChainId.POLYGON], 18, 'WMATIC', 'Wrapped MATIC',),
  [ChainId.AMOY]: new Token(ChainId.AMOY, ContractAddress.weth[ChainId.AMOY], 18, 'WMATIC', 'Wrapped MATIC',),
}

// export const WETH = new Token(ChainId.MAINNET, ContractAddress.weth[ChainId.MAINNET], 18, 'WETH', 'Wrapped ETH')

const tokens = {
  eth: {
    symbol: 'ETH',
    projectLink: 'https://ethereum.org/',
  },
  pcn: {
    symbol: 'Pizzaswap',
    address: {
      [ChainId.SEPOLIA]: ContractAddress.pcn[ChainId.SEPOLIA],
      // [ChainId.ETHEREUM]: ContractAddress.pcn[ChainId.ETHEREUM],
      // [ChainId.BSC]: ContractAddress.pcn[ChainId.BSC],
      [ChainId.BSC_TESTNET]: ContractAddress.pcn[ChainId.BSC_TESTNET],
      // [ChainId.POLYGON]: ContractAddress.pcn[ChainId.POLYGON],
      [ChainId.AMOY]: ContractAddress.pcn[ChainId.AMOY],
    },
    decimals: 18,
    projectLink: 'https://pizzaswap.io/',
  },
  weth: {
    symbol: 'WETH',
    address: {
      [ChainId.SEPOLIA]: ContractAddress.weth[ChainId.SEPOLIA],
      // [ChainId.ETHEREUM]: ContractAddress.weth[ChainId.ETHEREUM],
      // [ChainId.BSC]: ContractAddress.weth[ChainId.BSC],
      [ChainId.BSC_TESTNET]: ContractAddress.weth[ChainId.BSC_TESTNET],
      // [ChainId.POLYGON]: ContractAddress.weth[ChainId.POLYGON],
      [ChainId.AMOY]: ContractAddress.weth[ChainId.AMOY],
    },
    decimals: 18,
    projectLink: 'https://pizzaswap.io/',
  },
  busd: {
    symbol: 'USDT',
    address: {
      [ChainId.SEPOLIA]: ContractAddress.busd[ChainId.SEPOLIA],
      // [ChainId.ETHEREUM]: ContractAddress.busd[ChainId.ETHEREUM],
      // [ChainId.BSC]: ContractAddress.busd[ChainId.BSC],
      [ChainId.BSC_TESTNET]: ContractAddress.busd[ChainId.BSC_TESTNET],
      // [ChainId.POLYGON]: ContractAddress.busd[ChainId.POLYGON],
      [ChainId.AMOY]: ContractAddress.busd[ChainId.AMOY],
    },
    decimals: 18,
    projectLink: 'https://tether.io',
  },
  corn: {
    symbol: 'CORN',
    address: {
      [ChainId.SEPOLIA]: ContractAddress.corn[ChainId.SEPOLIA],
      // [ChainId.ETHEREUM]: ContractAddress.corn[ChainId.ETHEREUM],
      // [ChainId.BSC]: ContractAddress.corn[ChainId.BSC],
      [ChainId.BSC_TESTNET]: ContractAddress.corn[ChainId.BSC_TESTNET],
      // [ChainId.POLYGON]: ContractAddress.corn[ChainId.POLYGON],
      [ChainId.AMOY]: ContractAddress.corn[ChainId.AMOY],
    },
    decimals: 18,
    projectLink: 'https://pizzaswap.io/',
  },
}

export default tokens
