import { ChainId } from '@pancakeswap/sdk'
import sample from 'lodash/sample'

// Array of available nodes to connect to

const networkId = localStorage.getItem('CurrentChain') || process.env.REACT_APP_CHAIN_ID
export const nodes = {
  [ChainId.SEPOLIA]: "https://sepolia.drpc.org",
  [ChainId.BSC_TESTNET]: 'https://bsc-testnet-rpc.publicnode.com',
  [ChainId.AMOY]: 'https://rpc-amoy.polygon.technology',
}
// 'https://sepolia.infura.io/v3/0f2b2cf9a18245e9a104c2b71d9c4daf',

const getNodeUrl = () => {
  return nodes[networkId]
}

export default getNodeUrl
