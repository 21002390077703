import React from 'react'
import { HistoryIcon, Button, useModal } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'
import TransactionsModal from './TransactionsModal'

const Transactions = () => {
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)

  const { isDark } = useTheme()

  return (
    <>
      <Button
        variant="text"
        p={0}
        onClick={onPresentTransactionsModal}
        ml="16px"
        style={{
          backgroundColor: isDark ? `#636e72` : 'rgb(240, 243, 245)',
          width: 50,
          height: 50,
          borderRadius: '8px',
        }}
      >
        <HistoryIcon color={isDark ? 'white' : '#636e72'} width="28px" />
      </Button>
    </>
  )
}

export default Transactions
