import { ChainId } from '@pancakeswap/sdk'
import ContractAddress from '../contracts'

const wethName = {
  [ChainId.SEPOLIA]: 'WETH Token',
  [ChainId.BSC_TESTNET]: 'WBNB Token',
  [ChainId.AMOY]: 'WMATIC Token'
}

const wethSymbol = {
  [ChainId.SEPOLIA]: 'WETH',
  [ChainId.BSC_TESTNET]: 'WBNB',
  [ChainId.AMOY]: 'WMATIC'
}

export default {
  name: 'Pizzaswap Default List',
  timestamp: '2021-05-06T00:00:00Z',
  version: {
    major: 3,
    minor: 0,
    patch: 0,
  },
  tags: {},
  logoURI: `${window.location.origin}/images/tokens/0x0000000000000000000000000000000000000000.png`,
  keywords: ['pancake', 'default'],
  tokens: [
    {
      name: wethName[ContractAddress.networkId],
      symbol: wethSymbol[ContractAddress.networkId],
      address: `${ContractAddress.weth[ContractAddress.networkId]}`,
      chainId: Number(ContractAddress.networkId),
      decimals: 18,
      logoURI: `${window.location.origin}/images/tokens/${ContractAddress.weth[
        ContractAddress.networkId
      ].toLowerCase()}.png`,
    },
    {
      name: 'Pegged USDT',
      symbol: 'USDT',
      address: `${ContractAddress.busd[ContractAddress.networkId].toLowerCase()}`,
      chainId: Number(ContractAddress.networkId),
      decimals: 18,
      logoURI: `${window.location.origin}/images/tokens/${ContractAddress.busd[
        ContractAddress.networkId
      ].toLowerCase()}.png`,
    },
    {
      name: 'Pizzaswap',
      symbol: 'Pizza',
      address: `${ContractAddress.pcn[ContractAddress.networkId].toLowerCase()}`,
      chainId: Number(ContractAddress.networkId),
      decimals: 18,
      logoURI: `${window.location.origin}/images/tokens/${ContractAddress.pcn[
        ContractAddress.networkId
      ].toLowerCase()}.png`,
    },
  ],
}
