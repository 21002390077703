import React, { useState } from 'react'

const CACHE_KEY = 'CurrentChain'

const NetworkContext = React.createContext({ currentChain: 1, toggleChain: (_chainId: number) => null })

const ChainContext = ({ children }) => {
  const [currentChain, setCurrentChain] = useState(() => {
    const currentChainSetting = localStorage.getItem(CACHE_KEY)
    return currentChainSetting ? Number(currentChainSetting) : 1
  })

  const toggleChain = (chainId: number) => {
    localStorage.setItem(CACHE_KEY, chainId.toString())
    setCurrentChain(chainId)
    console.log('toggled chain', chainId)
  }

  return <NetworkContext.Provider value={{ currentChain, toggleChain }}>{children}</NetworkContext.Provider>
}

export { NetworkContext, ChainContext }
