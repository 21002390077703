import { ChainId, JSBI, Percent, Token, WETH } from '@pancakeswap/sdk'
import { BUSD, PCN } from './tokens'
import contracts from './contracts'

export const ROUTER_ADDRESS = contracts.router[contracts.networkId]

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  // [ChainId.ETHEREUM]: [WETH[ChainId.ETHEREUM], PCN[ChainId.ETHEREUM], BUSD[ChainId.ETHEREUM]],
  [ChainId.SEPOLIA]: [WETH[ChainId.SEPOLIA], PCN[ChainId.SEPOLIA], BUSD[ChainId.SEPOLIA]],
  // [ChainId.BSC]: [WETH[ChainId.BSC], PCN[ChainId.BSC], BUSD[ChainId.BSC]],
  [ChainId.BSC_TESTNET]: [WETH[ChainId.BSC_TESTNET], PCN[ChainId.BSC_TESTNET], BUSD[ChainId.BSC_TESTNET]],
  // [ChainId.POLYGON]: [WETH[ChainId.POLYGON], PCN[ChainId.POLYGON], BUSD[ChainId.POLYGON]],
  [ChainId.AMOY]: [WETH[ChainId.AMOY], PCN[ChainId.AMOY], BUSD[ChainId.AMOY]],
}

/**
 * Addittional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  // [ChainId.ETHEREUM]: {},
  [ChainId.SEPOLIA]: {},
  // [ChainId.BSC]: {},
  [ChainId.BSC_TESTNET]: {},
  // [ChainId.POLYGON]: {},
  [ChainId.AMOY]: {},
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  // [ChainId.ETHEREUM]: {},
  [ChainId.SEPOLIA]: {},
  // [ChainId.BSC]: {},
  [ChainId.BSC_TESTNET]: {},
  // [ChainId.POLYGON]: {},
  [ChainId.AMOY]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  // [ChainId.ETHEREUM]: [WETH[ChainId.ETHEREUM], PCN[ChainId.ETHEREUM], BUSD[ChainId.ETHEREUM]],
  [ChainId.SEPOLIA]: [WETH[ChainId.SEPOLIA], PCN[ChainId.SEPOLIA], BUSD[ChainId.SEPOLIA]],
  // [ChainId.BSC]: [WETH[ChainId.BSC], PCN[ChainId.BSC], BUSD[ChainId.BSC]],
  [ChainId.BSC_TESTNET]: [WETH[ChainId.BSC_TESTNET], PCN[ChainId.BSC_TESTNET], BUSD[ChainId.BSC_TESTNET]],
  // [ChainId.POLYGON]: [WETH[ChainId.POLYGON], PCN[ChainId.POLYGON], BUSD[ChainId.POLYGON]],
  [ChainId.AMOY]: [WETH[ChainId.AMOY], PCN[ChainId.AMOY], BUSD[ChainId.AMOY]],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  // [ChainId.ETHEREUM]: [WETH[ChainId.ETHEREUM], BUSD[ChainId.ETHEREUM]],
  [ChainId.SEPOLIA]: [WETH[ChainId.SEPOLIA], PCN[ChainId.SEPOLIA], BUSD[ChainId.SEPOLIA]],
  // [ChainId.BSC]: [WETH[ChainId.BSC], PCN[ChainId.BSC], BUSD[ChainId.BSC]],
  [ChainId.BSC_TESTNET]: [WETH[ChainId.BSC_TESTNET], PCN[ChainId.BSC_TESTNET], BUSD[ChainId.BSC_TESTNET]],
  // [ChainId.POLYGON]: [WETH[ChainId.POLYGON], PCN[ChainId.POLYGON], BUSD[ChainId.POLYGON]],
  [ChainId.AMOY]: [WETH[ChainId.AMOY], PCN[ChainId.AMOY], BUSD[ChainId.AMOY]],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  // [ChainId.ETHEREUM]: [[PCN[ChainId.ETHEREUM], WETH[ChainId.ETHEREUM]],[BUSD[ChainId.ETHEREUM], WETH[ChainId.ETHEREUM]]],
  [ChainId.SEPOLIA]: [
    [PCN[ChainId.SEPOLIA], WETH[ChainId.SEPOLIA]],
    [BUSD[ChainId.SEPOLIA], WETH[ChainId.SEPOLIA]],
  ],
  // [ChainId.BSC]: [[PCN[ChainId.BSC], WETH[ChainId.BSC]],[BUSD[ChainId.BSC], WETH[ChainId.BSC]]],
  [ChainId.BSC_TESTNET]: [
    [PCN[ChainId.BSC_TESTNET], WETH[ChainId.BSC_TESTNET]],
    [BUSD[ChainId.BSC_TESTNET], WETH[ChainId.BSC_TESTNET]],
  ],
  // [ChainId.POLYGON]: [[PCN[ChainId.POLYGON], WETH[ChainId.POLYGON]],[BUSD[ChainId.POLYGON], WETH[ChainId.POLYGON]]],
  [ChainId.AMOY]: [[PCN[ChainId.AMOY], WETH[ChainId.AMOY]], [BUSD[ChainId.AMOY], WETH[ChainId.AMOY]]],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = []

export { default as farmsConfig } from './farms'
export { default as poolsConfig } from './pools'
export { default as ifosConfig } from './ifo'
