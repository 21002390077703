import { ChainId } from '@pancakeswap/sdk'
import tokens from './tokens'
import { FarmConfig } from './types'
import ContractAddress from './contracts'

const wethSymbol = {
  [ChainId.SEPOLIA]: 'WETH',
  [ChainId.BSC_TESTNET]: 'WBNB',
  [ChainId.AMOY]: 'WMATIC'
}

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 1,
    lpSymbol: `Pizzaswap-${wethSymbol[ContractAddress.networkId]} LP`,
    lpAddresses: {
      // [ChainId.ETHEREUM]: String(ContractAddress.PcnLp[ChainId.ETHEREUM]),
      [ChainId.SEPOLIA]: String(ContractAddress.PcnLp[ChainId.SEPOLIA]),
      // [ChainId.BSC]: String(ContractAddress.PcnLp[ChainId.BSC]),
      [ChainId.BSC_TESTNET]: String(ContractAddress.PcnLp[ChainId.BSC_TESTNET]),
      // [ChainId.POLYGON]: String(ContractAddress.PcnLp[ChainId.POLYGON]),
      [ChainId.AMOY]: String(ContractAddress.PcnLp[ChainId.AMOY]),
    },
    token: tokens.pcn,
    quoteToken: tokens.weth,
  },
  {
    pid: 2,
    lpSymbol: `USDT-${wethSymbol[ContractAddress.networkId]} LP`,
    lpAddresses: {
      // [ChainId.ETHEREUM]: String(ContractAddress.BusdLp[ChainId.ETHEREUM]),
      [ChainId.SEPOLIA]: String(ContractAddress.BusdLp[ChainId.SEPOLIA]),
      // [ChainId.BSC]: String(ContractAddress.BusdLp[ChainId.BSC]),
      [ChainId.BSC_TESTNET]: String(ContractAddress.BusdLp[ChainId.BSC_TESTNET]),
      // [ChainId.POLYGON]: String(ContractAddress.BusdLp[ChainId.POLYGON]),
      [ChainId.AMOY]: String(ContractAddress.BusdLp[ChainId.AMOY]),
    },
    token: tokens.busd,
    quoteToken: tokens.weth,
  },
  /**
   * V3 by order of release (some may be out of PID order due to multiplier boost)
   */
]

export default farms
