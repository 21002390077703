import { ChainId } from '@pancakeswap/sdk'
import MULTICALL_ABI from './abi.json'
import ContractAddress from '../contracts'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  // [ChainId.ETHEREUM]: String(ContractAddress.multiCall[ChainId.ETHEREUM]),
  [ChainId.SEPOLIA]: String(ContractAddress.multiCall[ChainId.SEPOLIA]),
  // [ChainId.BSC]: String(ContractAddress.multiCall[ChainId.BSC]),
  [ChainId.BSC_TESTNET]: String(ContractAddress.multiCall[ChainId.BSC_TESTNET]),
  // [ChainId.POLYGON]: String(ContractAddress.multiCall[ChainId.POLYGON]),
  [ChainId.AMOY]: String(ContractAddress.multiCall[ChainId.AMOY]),
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
