import React from 'react'
import { Button, TuneIcon, NotificationDot, useModal } from '@pancakeswap/uikit'
import { useExpertModeManager } from 'state/user/hooks'
import useTheme from 'hooks/useTheme'
import SettingsModal from './SettingsModal'

export default function SettingsTab() {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)
  const [expertMode] = useExpertModeManager()

  const { isDark } = useTheme()

  return (
    <NotificationDot show={expertMode}>
      <Button
        variant="text"
        p={0}
        onClick={onPresentSettingsModal}
        id="open-settings-dialog-button"
        style={{
          backgroundColor: isDark ? `#636e72` : 'rgb(240, 243, 245)',
          width: 50,
          height: 50,
          borderRadius: '8px',
        }}
      >
        <TuneIcon color={isDark ? 'white' : '#636e72'} width="24px" />
      </Button>
    </NotificationDot>
  )
}
