import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: 'DASHBOARD',
    menus: [
      {
        label: 'Home',
        icon: 'HomeIcon',
        href: '/',
      },
      {
        label: 'Buy PizzaSwap',
        icon: 'BuyPizzaIcon',
        href: '/swap',
      },
      {
        label: 'Trade',
        icon: 'TradeIcon',
        items: [
          {
            label: 'Exchange',
            href: '/swap',
          },
          {
            label: 'Liquidity',
            href: '/pool',
          },
        ],
      },
    ],
  },
  {
    label: 'PAGES',
    menus: [
      {
        label: 'Farms',
        icon: 'FarmIcon',
        href: '/farms',
      },
      {
        label: 'Staking',
        icon: 'PoolIcon',
        href: '/pools',
      },
      {
        label: 'Lottery',
        icon: 'TicketIcon',
        href: '/lottery',
      },
      {
        label: 'Referral',
        icon: 'ReferIcon',
        href: '/referral',
      },
      {
        label: 'TechFund Audit',
        icon: 'AuditIcon',
        href: '/audit',
      },
    ],
  },
  {
    label: 'MORE',
    menus: [
      {
        label: 'Docs',
        icon: 'DocsIcon',
        href: 'https://docs.pizzaswap.finance',
      },
      {
        label: 'Github',
        icon: 'GithubIcon',
        href: 'https://github.com/pizzaswap',
      },
      {
        label: 'Medium',
        icon: 'MediumIcon',
        href: 'https://pizzaswap.medium.com',
      },
    ],
  },
]

export default config
